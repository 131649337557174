import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Container, Badge, Row, Col, FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FiCopy, FiMail, FiShoppingCart } from 'react-icons/fi';
import {
    IoArchiveOutline,
    IoServerOutline,
    IoDocumentsOutline,
} from 'react-icons/io5';
import { Icon } from '@chakra-ui/react';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import messages from './TransactionListPage.intl';
import * as actions from '../../store/actions/index';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import EvtTable from '../../components/EvtTable/EvtTable';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import { filterPropertyTypes } from '../../utils/enums';
import { AuthContext } from '../../context/AuthContext';
import BeatMeatBallMenu from '../../components/BeatMeatBallMenu';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import TooltipItem from '../../components/Tooltip/Tooltip';
import DynamicFilterModal from '../../components/DynamicFilterModal/DynamicFilterModal';
import { handleCpfCnpj } from '../../utils/handleCpfCnpj';
import { paymentMethodTypeParse } from '../../utils/paymentMethodParser';
import { paymentOperatorParse } from '../../utils/paymentOperatorParser';
import { DateTimeParser } from '../../utils/dateTimeParser';

class TransactionListPage extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
        showSendEmailModal: false,
        showDynamicFilterModal: false,
    };

    reloadTransactionsList = () => {
        const { query } = this.state;
        this.props.onInitTransactions(query);
        this.props.onInitTransactionsCount(query);
    };

    handleGetTrProps = (state, rowInfo) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: () =>
                this.props.history.push(`/transactions/${rowInfo.original.id}`),

            /*  className: !rowInfo.original.isAvailable ? 'disabled-row' : '',
            title: !rowInfo.original.isAvailable
                ? intl.formatMessage(messages.unavailableTransaction)
                : '', */
        };
    };

    handleNewClick = () => {
        this.props.history.push(`/transactions/new`);
    };

    handleImportClick = () => {
        this.props.history.push(`/transactions/import`);
    };

    handleRefreshButtonClick = () => {
        this.reloadTransactionsList();
    };

    handleFiltersChange = (filters) => {
        this.setState({
            filters,
        });
    };

    handleDynamicFilterModalToogle = () => {
        this.setState({
            showDynamicFilterModal: !this.state.showDynamicFilterModal,
        });
    };

    handleFormSubmit = (e, value) => {
        this.props.onExportExcel(value.filters.firstFilter);
    };

    handleEvTableStateChange = (query) => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadTransactionsList();
            },
        );
    };

    handleSendEmailToggleModal = () => {
        this.setState({
            showSendEmailModal: !this.state.showSendEmailModal,
        });
    };

    handleSendEmailButtonClick = (event, values) => {
        values.alternativeEmail !== ''
            ? this.props.onResendMailTransaction(
                  this.state.transactionId,
                  values.alternativeEmail,
              )
            : this.props.onResendMailTransaction(this.state.transactionId);
        this.handleSendEmailToggleModal();
    };

    handleCopyLinkClick = (id) => {
        this.props.onCopyLink(id);
        const interval = setInterval(() => {
            if (!this.props.loadingList && this.props.paymentLink) {
                const textField = document.createElement('textarea');

                textField.innerText = this.props.paymentLink;

                document.body.appendChild(textField);

                textField.select();
                document.execCommand('copy');

                textField.remove();
                clearInterval(interval);
            }
        }, 100);
    };

    render() {
        const {
            intl,
            loadingList,
            transactions,
            transactionsCount,
            loadingExport,
        } = this.props;
        const { filters } = this.state;

        const transactionExportButtonEnabled = this.context.hasClaim(
            'transaction-export',
        );

        const canResendEmail = this.context.hasClaim('transaction-resend-mail');

        const properties = [
            {
                key: 'transactionId',
                value: intl.formatMessage(messages.transactionCode),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'status',
                value: intl.formatMessage(messages.status),
                type: filterPropertyTypes.ENUM,
                enums: [
                    { key: 0, value: 'Pendente' },
                    { key: 1, value: 'Cancelado' },
                    { key: 2, value: 'Completado' },
                ],
            },
            {
                key: 'clientNumber',
                value: intl.formatMessage(messages.clientNumber),
                type: filterPropertyTypes.NUMBER,
            },
            {
                key: 'clientName',
                value: intl.formatMessage(messages.clientName),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'clientCpfCnpj',
                value: intl.formatMessage(messages.clientDocumentNumber),
                type: filterPropertyTypes.TEXT,
                map: (input) => input.replace(/[^0-9]/g, ''),
            },
            {
                key: 'operatorId',
                value: intl.formatMessage(messages.operatorId),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'source',
                value: intl.formatMessage(messages.source),
                type: filterPropertyTypes.ENUM,
                enums: [
                    { key: 0, value: 'Pagamento Online' },
                    { key: 1, value: 'Link de pagamento' },
                    { key: 2, value: 'SAP' },
                    { key: 3, value: 'Integração de arquivo' },
                    { key: 4, value: 'Gerado por contrato' },
                ],
            },
            {
                key: 'paymentMethodType',
                value: intl.formatMessage(messages.transactionpaymentMethod),
                type: filterPropertyTypes.ENUM,
                enums: [
                    { key: 1, value: 'Cartão de Crédito' },
                    { key: 2, value: 'Carteira digital' },
                    { key: 5, value: 'Pix' },
                    { key: 6, value: 'Dinheiro' },
                ],
            },
            {
                key: 'collectionId',
                value: intl.formatMessage(messages.selectedCollectionIds),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'referenceDocument',
                value: intl.formatMessage(messages.referenceDocument),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'createdAt',
                value: intl.formatMessage(messages.createdAt),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'paymentDate',
                value: intl.formatMessage(messages.payDate),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'paymentValue',
                value: intl.formatMessage(messages.transactionValue),
                type: filterPropertyTypes.NUMBER,
                map: (input) => input.replace(/\./g, '').replace(/,/g, '.'),
            },
        ];

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.transactionCode)}
                        </span>
                    </div>
                ),
                accessor: 'transactionId',
                minWidth: 160,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.source)}</span>
                    </div>
                ),
                maxWidth: 60,
                accessor: 'source',
                style: {
                    cursor: 'default',
                },
                sortable: true,
                Cell: (row) => {
                    let icon;
                    switch (row.value) {
                        case 0:
                            icon = (
                                <>
                                    <Icon
                                        as={FiShoppingCart}
                                        w={6}
                                        h={6}
                                        id='Tooltip-creditCard'
                                    />
                                    <TooltipItem
                                        id='creditCard'
                                        message='Pagamento Online'
                                    />
                                </>
                            );
                            break;
                        case 1:
                            icon = (
                                <>
                                    <Icon
                                        as={IoServerOutline}
                                        w={6}
                                        h={6}
                                        id='Tooltip-paymentLink'
                                    />
                                    <TooltipItem
                                        id='paymentLink'
                                        message='Link de Pagamento'
                                    />
                                </>
                            );
                            break;
                        case 2:
                            icon = (
                                <>
                                    <Icon
                                        as={IoServerOutline}
                                        w={6}
                                        h={6}
                                        id='Tooltip-sap'
                                    />
                                    <TooltipItem id='sap' message='SAP' />
                                </>
                            );
                            break;
                        case 3:
                            icon = (
                                <>
                                    <Icon
                                        as={IoArchiveOutline}
                                        w={6}
                                        h={6}
                                        id='Tooltip-achiveIntegration'
                                    />
                                    <TooltipItem
                                        id='achiveIntegration'
                                        message='Integração de arquivo'
                                    />
                                </>
                            );
                            break;
                        case 4:
                            icon = (
                                <>
                                    <Icon
                                        as={IoDocumentsOutline}
                                        w={6}
                                        h={6}
                                        id='Tooltip-contract-billing'
                                    />
                                    <TooltipItem
                                        id='contract-billing'
                                        message='Gerado por contrato'
                                    />
                                </>
                            );
                            break;
                        default:
                            icon = <></>;
                    }
                    return icon;
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.createDate)}</span>
                    </div>
                ),
                accessor: 'createdAt',
                // eslint-disable-next-line consistent-return
                Cell: (row) => {
                    if (row.value) {
                        return DateTimeParser(row.value);
                    }
                },
                sortable: true,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.proposedValue)}
                        </span>
                    </div>
                ),
                accessor: 'totalValue',
                Cell: (props) =>
                    props.value > 0 &&
                    new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(props.value),
                sortable: false,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.client)}</span>
                    </div>
                ),

                Cell: (row) => {
                    const { clientName, clientDocumentNumber } = row.original;

                    const document = handleCpfCnpj(clientDocumentNumber);

                    if (clientName) {
                        return `${clientName} / ${document}`;
                    }

                    return `${document}`;
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(
                                messages.transactionpaymentMethod,
                            )}
                        </span>
                    </div>
                ),

                Cell: (row) => {
                    let paymentMethod;
                    const paymentsReceived = row.original.paymentsReceived;

                    if (paymentsReceived.length === 1) {
                        paymentMethod = `${paymentOperatorParse(
                            paymentsReceived[0].paymentOperator,
                        )} / 
                        ${paymentMethodTypeParse(
                            paymentsReceived[0].paymentMethodType,
                        )}`;
                    } else if (paymentsReceived.length > 1) {
                        paymentMethod = 'Múltiplos';
                    }
                    return paymentMethod;
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.payDate)}</span>
                    </div>
                ),
                accessor: 'payDate',
                // eslint-disable-next-line consistent-return
                Cell: (row) => {
                    if (row.value) {
                        return DateTimeParser(row.value);
                    }
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.transactionValue)}
                        </span>
                    </div>
                ),
                accessor: 'paymentValue',
                Cell: (props) =>
                    props.value > 0 &&
                    new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(props.value),
                sortable: false,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.comissionFeeValue)}
                        </span>
                    </div>
                ),
                accessor: 'comissionFeeValue',
                Cell: (props) =>
                    new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(props.value || 0),
                sortable: false,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.status)}</span>
                    </div>
                ),
                accessor: 'status',
                Cell: (row) => {
                    let status = '';
                    let color = 'warning';

                    switch (row.value) {
                        case 0:
                            status = 'Pendente';
                            break;
                        case 1:
                            status = 'Cancelado';
                            break;
                        case 2:
                            status = 'Completado';
                            color = 'success';
                            break;
                        default:
                    }
                    return (
                        <Badge
                            style={{ fontSize: '0.8rem', color: '#ffffff' }}
                            color={color}
                            pill
                        >
                            {status}
                        </Badge>
                    );
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{}</span>
                    </div>
                ),
                accessor: 'statusDescription',
                style: {
                    overflow: 'visible',
                    textAlignLast: 'right',
                },
                maxWidth: 70,
                Cell: (row) => {
                    const ItemsBeatMenu = [];
                    if (canResendEmail) {
                        ItemsBeatMenu.push(
                            {
                                label: 'Reenviar link de pagamento',
                                icon: <FiMail />,
                                onClick: (e) => {
                                    this.setState({
                                        showSendEmailModal: true,
                                        transactionId:
                                            row.original.transactionId,
                                    });
                                    e.stopPropagation();
                                },
                            },
                            {
                                label: 'Copiar link de pagamento',
                                icon: <FiCopy />,
                                onClick: (e) => {
                                    this.handleCopyLinkClick(
                                        row.original.transactionId,
                                    );
                                    e.stopPropagation();
                                },
                            },
                        );
                    } else {
                        ItemsBeatMenu.push({
                            label: 'Copiar link de pagamento',
                            icon: <FiCopy />,
                            onClick: (e) => {
                                this.handleCopyLinkClick(
                                    row.original.transactionId,
                                );
                                e.stopPropagation();
                            },
                        });
                    }
                    return (
                        <>
                            {row.original.source !== 0 &&
                                row.value === 'Pendente' && (
                                    <BeatMeatBallMenu items={ItemsBeatMenu} />
                                )}
                        </>
                    );
                },
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/transactions',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={
                                this.props.transactionsCount +
                                intl.formatMessage(messages.subtitle)
                            }
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                        {transactionExportButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleDynamicFilterModalToogle}
                                style={{ marginRight: 15 }}
                            >
                                Exportar
                            </button>
                        )}
                        <button
                            className='new-btn'
                            onClick={this.handleImportClick}
                            style={{ marginRight: 15 }}
                        >
                            Importar
                        </button>
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={transactions}
                        length={transactionsCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'payDate',
                                desc: true,
                            },
                        ]}
                    />
                </section>
                <Modal
                    title={intl.formatMessage(messages.modalTitle)}
                    isOpen={this.state.showSendEmailModal}
                    key='newPasswordConfirmationModal'
                    handleToggle={this.handleSendEmailToggleModal}
                    type={modalTypes.SUCCESS}
                >
                    <span>
                        Informe um email caso queria enviar a cobrança para um
                        destinatário específico e ignorar os contatos
                        cadastrados para esse cliente
                    </span>
                    <AvForm onValidSubmit={this.handleSendEmailButtonClick}>
                        <Row>
                            <Col lg={12} md={12}>
                                <FormGroup>
                                    <AvField
                                        label={intl.formatMessage(
                                            messages.alternativeEmail,
                                        )}
                                        type='text'
                                        name='alternativeEmail'
                                        id='alternativeEmail'
                                        value=''
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button type='submit' className='btn-submit'>
                                    {intl.formatMessage(messages.yesText)}
                                </button>
                            </Col>
                            <Col>
                                <button
                                    type='button'
                                    className='btn-delete'
                                    onClick={this.handleSendEmailToggleModal}
                                >
                                    {intl.formatMessage(messages.noText)}
                                </button>
                            </Col>
                        </Row>
                    </AvForm>
                </Modal>
                <DynamicFilterModal
                    filterEntityTitle='Exportar para Excel'
                    filters={[
                        {
                            showAddButton: true,
                            property: '',
                            operator: '',
                            value: '',
                            type: filterPropertyTypes.DATE,
                            enums: [],
                            id: 'firstFilter',
                        },
                    ]}
                    isOpen={this.state.showDynamicFilterModal}
                    handleToggle={this.handleDynamicFilterModalToogle}
                    loading={false}
                    properties={[
                        {
                            key: 'paymentDate',
                            value: intl.formatMessage(messages.payDate),
                            type: filterPropertyTypes.DATE,
                        },
                    ]}
                    handleFormSubmit={this.handleFormSubmit}
                    submitButtonText='Exportar'
                    loadingExport={loadingExport}
                />
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        transactions: state.transactionReducer.transactions || [],
        transactionsCount: state.transactionReducer.transactionsCount || 0,
        paymentLink: state.transactionReducer.paymentLink,
        loadingList: state.transactionReducer.loadingList,
        loadingExport: state.transactionReducer.loadingExport,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTransactions: (filter) =>
            dispatch(actions.initTransactions(filter)),
        onCopyLink: (id) => dispatch(actions.transactionCopyLink(id)),
        onInitTransactionsCount: (filter) =>
            dispatch(actions.initTransactionsCount(filter)),
        onResendMailTransaction: (transactionId, email) =>
            dispatch(actions.transactionResendEmail(transactionId, email)),
        onExportExcel: (query) => dispatch(actions.downloadCsv(query)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TransactionListPage),
);
